import { Link } from "gatsby";
import React from "react";
import { Header } from "../components/header";
import { Layout } from "../components/layout";
import SEO from "../components/seo";
import { EasyGrid } from "../components/styles/EasyGrid";
import { LinkButton } from "../components/styles/LinkButton";
import { TABLET_BR } from "../utils/style";
import { useWindowSize } from "../utils/window-size";

const IndexPage = () => {
  let size = useWindowSize();

  const header = (
    <>
      <h1>Winfried Neun</h1>
      <h4>Wirtschaftspsychologe und Verhaltensökonom</h4>
      <h4>
        Als Wirtschaftspsychologe erkläre ich Ihnen die psychologischen
        Mechanismen die hinter dem Verhalten von Menschen stehen.
      </h4>
      <h4>Ihr Beitrag: Zuhören, verstehen, Verhalten gestalten.</h4>
    </>
  );

  return (
    <Layout>
      <SEO title="" keywords={[`gatsby`, `application`, `react`]} />
      <Header>
        {size.width > TABLET_BR ? (
          <>
            <img className="wn" src={require("../images/wn.png")} />
            <div className="tagline">{header}</div>
            <EasyGrid className="links">
              <Link to="/meine-kompetenzen#vita">
                <LinkButton>Vita</LinkButton>
              </Link>
              <Link to="/meine-kompetenzen#presse">
                <LinkButton outline>Presse</LinkButton>
              </Link>
            </EasyGrid>
          </>
        ) : (
          <>
            <div className="circle-text">{header}</div>
            <div
              className="circle"
              style={{
                backgroundImage: `url(${require("../images/wn_stehend_sw.jpg")})`
              }}
            />
          </>
        )}
      </Header>
    </Layout>
  );
};

export default IndexPage;
